import styled from '@emotion/styled';
import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

const NavbarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 40px;

  a {
    text-decoration: none;
  }
`;

const NavbarItem = styled.div<{ active: boolean }>`
  font-family: 'Mark Pro', 'Avenir', sans-serif;
  font-weight: ${({ active }) => (active ? '500' : '400')};
  text-transform: uppercase;
  color: white;
`;

const Navbar = () => {
  const location = useLocation();

  const items = useMemo(
    () => [
      {
        name: 'Home',
        link: '/',
        active: location.pathname === '/'
      },
      {
        name: 'Projects',
        link: '/projects',
        active: location.pathname === '/projects'
      },
      {
        name: 'About',
        link: '/about',
        active: location.pathname === '/about'
      },
      {
        name: 'Contact',
        link: '/contact',
        active: location.pathname === '/contact'
      }
    ],
    [location]
  );

  return (
    <NavbarWrapper>
      {items.map(item => (
        <Link key={item.link} to={item.link}>
          <NavbarItem active={item.active}>{item.name}</NavbarItem>
        </Link>
      ))}
    </NavbarWrapper>
  );
};

export default Navbar;
