import styled from '@emotion/styled';

const Title = styled.h1`
  font-family: 'Playfair Display', serif;
  font-weight: bolder;
  font-size: 10em;
  letter-spacing: 4px;
  text-align: center;
  margin: 0;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
`;

const HomeContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const BackgroundImage = styled.div`
  margin-top: 106px;
  background: black;
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-image: url('./abstract-wave-pattern.jpg');
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(260deg, rgba(0, 0, 0, 0.6) 5%, rgba(0, 0, 0, 0) 100%);
  }
`;

const Face = styled.img`
  position: fixed;
  width: 580px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 20;
`;

export const Home = () => (
  <HomeContainer>
    <Title>OLIVIER</Title>
    <BackgroundImage />
    <Face src="./face-dark.png" />
  </HomeContainer>
);
