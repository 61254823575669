import { Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import { Home } from './components/Home';
import { useMeasure } from './hooks/useMeasure';

function App() {
  const [ref, rect] = useMeasure();

  console.log(rect);

  return (
    <div className="App">
      <Header ref={ref} />
      <main style={{ height: `calc(100% - ${rect.height}px)` }}>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="about" element={<About />} /> */}
        </Routes>
      </main>
    </div>
  );
}

export default App;
